<template>
    <div>
        <b-container>
            <section class="map">
                <div class="sector">
                    <h2 class="main-title">Map</h2>
                    <p class="main-subtitle">Find the best service providers in your area</p>
                    <div v-if="!getAgenciesPending" class="mb-3 mt-3">
                        <Spinner class="mb-2" size="medium" line-fg-color="#e91e63" />
                    </div>
                    <b-row v-else>
                        <b-col cols="12" lg="4">
                            <Spinner v-if="loader" class="map-loader" size="medium mb-2" line-fg-color="#e91e63" />
                            <vue-custom-scrollbar :settings="settings" class="scroll-area" ref="scrollWrapper" infinite-wrapper>
                                <div :class="loader ? 'disabled-element' : '' " class="map__box" >
                                    <div v-for="agency in agencies" :key="agency.id" class="map__element">
                                        <div v-if="agency.ribbon" class="custom-ribbon custom-ribbon--right">
                                            <span class="custom-ribbon__text custom-ribbon__text--blue">Featured</span>
                                        </div>
                                        <h3 class="map__title" v-line-clamp:22="1">{{ agency.name }}</h3>
                                        <span v-if="agency.rating" :id="agency.id" class="agency__star-tooltip"><star-rating :star-size="18" border-color="transparent" :show-rating="false" :rating="agency.rating" :increment="0.01"  :read-only="true"></star-rating>
                                            <b-tooltip :target="agency.id">
                                                Rating: {{ agency.rating }}
                                            </b-tooltip>
                                        </span>
                                        <span v-line-clamp:14="1" v-if="agency.email" class="map__info"><span class="agencies__star-tooltip" v-b-tooltip title="E-mail"><simple-line-icons icon="envelope" size="small" color="#504e70" /></span><a class="map__link" :href="'mailto:'+ agency.email">{{ agency.email }}</a></span>
                                        <span v-line-clamp:14="1" v-if="agency.phone" class="map__info"><span class="agencies__star-tooltip" v-b-tooltip title="Phone number"><simple-line-icons icon="phone" size="small" color="#504e70" /></span>{{ agency.phone }}</span>
                                        <span v-line-clamp:14="1" class="map__info"><span class="agencies__star-tooltip" v-b-tooltip title="Website"><simple-line-icons icon="globe" size="small" color="#504e70" /></span><a class="map__link" :href="agency.website" target="_blank">website</a></span>
                                        <span v-line-clamp:14="1" class="map__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ agency.city }}, {{ agency.country.text }}</span>
                                        <b-row class="mt-3">
                                            <b-col cols="12" sm="6" lg="12" xl="6">
                                                <button @click.prevent.stop="showLocation(agency.lat, agency.lng)" class="agencies__button agencies__button--location"><simple-line-icons icon="locationPin" size="small" color="#504e70" />Show location</button>
                                            </b-col>
                                            <b-col cols="12" sm="6" lg="12" xl="6">
                                                <router-link class="agencies__button agencies__button--profile" :to="{ name: 'agency', params: { id: agency.id }}"><simple-line-icons icon="grid" size="small" color="#fff" />View profile</router-link>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <infinite-loading v-if="getAgenciesPending" @infinite="infiniteHandler" :distance="400">
                                        <span class="no-results" slot="no-results">No more data</span>
                                        <span class="no-results" slot="no-more">No more data</span>
                                        <span slot="spinner"></span>
                                    </infinite-loading>
                                </div>
                            </vue-custom-scrollbar>
                        </b-col>
                        <b-col cols="12" lg="8">
                            <div class="map__location-map">
                                <l-map :zoom="currentZoom" :center="center" @update:zoom="zoomUpdate" @update:center="centerUpdate" :options="{zoomControl: false}">
                                    <l-tile-layer
                                    :url="url"
                                    layer-type="base"></l-tile-layer>
                                    <v-marker-cluster>
                                        <l-marker v-for="agency in getStoreAgencies" :key="agency.id" :lat-lng="[agency.lat, agency.lng]">
                                            <l-popup><b>{{ agency.name}}</b><br> {{ agency.address }}<br> {{ agency.city }}, {{ agency.country.text }}</l-popup>
                                        </l-marker>
                                    </v-marker-cluster>
                                    <l-control-zoom position="topright"></l-control-zoom>
                                </l-map>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import StarRating from 'vue-star-rating'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import InfiniteLoading from 'vue-infinite-loading'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlZoom
} from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

export default {
  components: {
    SimpleLineIcons,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlZoom,
    Spinner,
    StarRating,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    InfiniteLoading,
    vueCustomScrollbar
  },
  data: function () {
    return {
      // url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 12,
      currentZoom: 12,
      center: [40.73461490255838, -73.98722073063256],
      currentCenter: L.latLng(40.73461490255838, -73.98722073063256),
      agencies: [],
      loader: false,
      limit: 10,
      settings: {
        wheelSpeed: 0.7
      }
    }
  },
  methods: {
    showLocation (lat, lng) {
      this.currentZoom = 17
      setTimeout(function () { this.center = [lat, lng] }.bind(this), 400)
    },
    zoomUpdate (zoom) {
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    },
    infiniteHandler ($state) {
      this.loader = true
      setTimeout(() => {
        const append = this.getStoreAgencies.slice(this.agencies.length, this.agencies.length + this.limit)
        this.agencies = this.agencies.concat(append)
        $state.loaded()
        this.loader = false
        if (this.getStoreAgencies.length == this.agencies.length && this.agencies.length !== 0) {
          $state.complete()
        }
        setTimeout(function () {
          if (this.getStoreAgencies.length == 0) {
            $state.complete(); this.loader = false
          }
        }.bind(this), 5000)
      }, 500)
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAgencies')
  },
  created () {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = [position.coords.latitude, position.coords.longitude]
      })
    }
  },
  computed: {
    ...mapGetters([
      'getAgenciesPending',
      'getAgencies'
    ]),
    getStoreAgencies () {
      var storeAgencies = Object.values(this.getAgencies).reverse().sort((a, b) => b.ribbon - a.ribbon)

      var sortedAgencies = storeAgencies.sort((a, b) => b.rating - a.rating)

      return sortedAgencies
    }
  }
}
</script>
